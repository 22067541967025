<template>
    <v-app id="login-home" >
        <div class="login">
            <div id="okta-signin-container" />
        </div>
    </v-app>
</template>

<script>
import OktaSignIn from "@okta/okta-signin-widget"
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css"

export default {
    name: "Login",
    mounted: function() {
        this.$nextTick(function() {
            this.widget = new OktaSignIn({
                baseUrl: "https://" + process.env.VUE_APP_OKTA_DOMAIN,
                clientId: process.env.VUE_APP_OKTA_CLIENTID,
          //      redirectUri: "http://localhost:8080/login/callback",
          //      redirectUri: "https://dlsstagedash.z19.web.core.windows.net/login/callback",
                redirectUri: window.location.origin + "/login/callback",
                logo: require("@/assets/logo.png"),
                authParams: {
                    pkce: true,
                    issuer:
                        "https://" + process.env.VUE_APP_OKTA_DOMAIN + "/oauth2/default",
                    display: "page",
                    scopes: ["openid", "profile", "email"]
                }
            })
            this.widget.showSignInAndRedirect({ el: "#okta-signin-container" })
        })
    },
    destroyed() {
        // Remove the widget from the DOM on path change
        this.widget.remove()
    }
}
</script>
